import { useEffect } from "react";

const SideContent = () => {
  useEffect(() => {
    window.addEventListener("resize", () => {
      document.querySelector(".side-content").style.display = "none";
    });
  }, []);

  return (
    <div className="side-content animate__animated">
      <span className="side-content__text">
        <a href="mailto:tmcrs123@gmail.com">tmcrs123@gmail.com</a>
      </span>
      <div className="side-content__line"></div>
    </div>
  );
};

export default SideContent;
