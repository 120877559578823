export const landingData = {
  name: "Tiago Rodrigues"
};

export const aboutData = {
  about1: "Hello! I'm <span class=\"text--accent\">Tiago</span> and I like solving problems that involve code.",
  about2:"I haven't always been a software developer. I have a master's degree in <span class=\"text--accent\">finance</span> and I worked in the investment banking industry for a few years. In 2016, I realised finance wasn't really something I enjoyed doing so I decided to embrace software development as a new career path.",
  about3:"Today, I'm a developer who particularly likes working on the <span class=\"text--accent\">frontend</span> but I'm also capable of writing <span class=\"text--accent\">backend</span> code. Occasionally, I dabble in <span class=\"text--accent\">UI</span> design and <span class=\"text--accent\">UX</span> too.",
  about4: "Here a few technologies I've been working with lately:",
  technologies: ["Angular", "React", "Typescript", "AWS", "NodeJs", "Mongo DB"],
};

export const workData = [
  {
    index: 3,
    endDate: "Today",
    startDate: "Aug 2021",
    title: "Frontend Developer",
    companyName: "Unilink UK",
    descriptions: [
      "Frontend developer in rotating teams of 4 to 6 elements",
      "Developed and maintained an offender management system used in prisons around the world",
      "Technologies used: Angular, Jest, Docker, SQL",
    ],
  },
  {
    index: 2,
    endDate: "Aug 2021",
    startDate: "Aug 2018",
    title: "Full-Stack Developer",
    companyName: "ALD Automotive UK",
    descriptions: [
      "Full-Stack developer in a FCA regulated company",
      "Part of a team of 4 software developers and 2 business analysts, working alongside 9 similarly sized teams",
      "Developed and maintained ALD's microservices infrastructure and frontend",
      "Technologies used: .NET, Angular, RabbitMQ, Oracle SQL and Azure Dev Ops",
    ],
  },
  {
    index: 1,
    endDate: "Dec 2017",
    startDate: "Apr 2017",
    title: ".NET Consultant",
    companyName: "CGI Portugal",
    descriptions: [
      "Full-Stack developer in a team of 2 developers",
      "Developed and maintained a .NET application for internal employee management",
      "Implemented a timesheet and feedback management system",
      "Technologies used: .NET, SQL, Razor, JQuery and Bootstrap",
    ],
  },
  {
    index: 0,
    endDate: "Oct 2016",
    startDate: "Mar 2015",
    title: "Financial/Marketing Analyist",
    companyName: "Santander, BCP and Uniplaces (Startup)",
    descriptions: [
      "Financial Analyist for 2 of the major portuguese investment banks and Marketing Analyist for a startup",
      "Responsible for building models for portfolio/marketing analysis",
      "Main tools: Excel and SQL. I also did a bit of work using Google Adwords and Facebook Ads",
    ],
  },
];

export const galleryData = [
  {
    name: "The stuff I never remember",
    description:
      "I wanted to learn AWS and I needed a place to write all the git and bash commands I never remember. Therefore, I created this app. It's still a work in progress but it's good enough for me to save my notes so I deployed it",
    technologies: [
      "AWS (EC2, Lambda, Cloudfront, Cognito)",
      "Angular",
      "Express",
      "Typescript",
      "MongoDB",
    ]
  },
  {
    name: "This website - My Portfolio",
    description:
      "LinkedIn is alright but every page looks the same there. So I created my own portfolio. It was also a pretext to brush up on my React skills",
    technologies: ["AWS", "React", "Javascript", "CSS"],
  },
  {
    name: "Apartment 65 - Hotel Reservations",
    description: "A hotel management app - Coming soon",
    technologies: ["AWS", "React", "Javascript", "CSS"],
  },
];

export const smallProjectsData = [
  {
    title: "App Initialiser",
    description: "Implementation of the APP_INITILIZER token in Angular",
    url: "http://angular-app-initializer.s3-website.eu-west-3.amazonaws.com/"
  },
  {
    title: "Wizard form",
    description: "A multi-page wizard form in Angular",
    url: "http://angular-wizard.s3-website.eu-west-3.amazonaws.com/details"
  },
  {
    title: "Drag & Drop",
    description: "Implementation of a drag and drop mechanism in Angular",
    url: "http://angular-drag-drop.s3-website-us-east-1.amazonaws.com/"
  },
  {
    title: "Services dashboard",
    description: "A mock healthcheck dashboard created using Angular Material",
    url: "http://angular-release-board.s3-website.eu-west-3.amazonaws.com/"
  }
]

export const contactData = {
  text: "Even though I’m not currently looking for a job, I’m always happy to talk. If you’d like to reach out, click the button below. I’ll reply to you as soon as possible.",
};
