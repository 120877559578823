import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { workData } from "../../assets/data";

const Carousel = (props) => {
  let newIndex;
  function handlePrevClick(e) {
    if (selectedIndex === 0) return;

    newIndex = selectedIndex - 1;

    setSelectedIndex(newIndex);
    setSelectedItem(data[newIndex]);

    const element = document.querySelector("#carousel");
    element.classList.add("carousel__prev__btn");
    element.addEventListener("animationend", () => {
      element.classList.remove("carousel__prev__btn");
    });
  }

  function handleNextClick(e) {
    let newIndex;
    if (selectedIndex >= data.length - 1) {
      newIndex = 0;
    } else {
      setSelectedIndex(selectedIndex + 1);
      newIndex = selectedIndex + 1;
    }

    setSelectedIndex(newIndex);
    setSelectedItem(data[newIndex]);

    const element = document.querySelector(".animate");
    element.classList.add("carousel__next__btn");
    element.addEventListener("animationend", () => {
      element.classList.remove("carousel__next__btn");
    });
  }

  const [data] = useState(workData.sort((a, b) => b.index - a.index));
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedItem, setSelectedItem] = useState(data[selectedIndex]);

  return (
    <div className="carousel">
      <div className="carousel__date-switcher">
        <button
          title="previous"
          className={`carousel__prev__btn btn--icon ${
            selectedIndex === 0 ? "btn--hide" : ""
          }`}
          onClick={handlePrevClick}
        >
          <FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon>
        </button>
        <small>
          {selectedItem.endDate} - {selectedItem.startDate}
        </small>
        <button
          title="next"
          className={`carousel__next__btn btn--icon ${
            selectedIndex === data.length - 1 ? "btn--hide" : ""
          }`}
          onClick={handleNextClick}
        >
          <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
        </button>
      </div>
      <div className="animate">
        <h2>
          <span>{selectedItem.title}&nbsp;</span>
          <span className="text--accent">@ {selectedItem.companyName}</span>
        </h2>
        <ul id={props.id}>
          {selectedItem.descriptions.map((desc, i) => {
            return <li key={i}>{desc}</li>;
          })}
        </ul>
      </div>
    </div>
  );
};

export default Carousel;
