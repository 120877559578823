import { landingData } from "../../assets/data";

const Landing = () => {
  return (
    <div className="landing__container slide slide--lg animate__animated animate__fadeIn animate__delay-4">
      <h1>{landingData.name}</h1>
      <h2>
        Software <span className="text--accent">developer</span> with an eye for
        <span className="text--accent">&nbsp;UI/UX</span>
      </h2>
      <a href="#about">
        <button className="landing__container__btn">Learn More</button>
      </a>
    </div>
  );
};

export default Landing;
